var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-divider", [_vm._v(_vm._s(_vm.$t("lbl_credit_approval")))]),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    pagination: false,
                    scroll: { y: 320 },
                    loading: _vm.loading.table,
                    size: "small"
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "salesOrderNumber",
                      attrs: { "data-index": "salesOrderNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name:
                                        "sales.transactionsales.salesorder.detail",
                                      params: { id: record.id }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.salesOrderNumber || "-") +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_sales_order_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "customerName",
                      attrs: { "data-index": "customerName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.customerName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_customer_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "creditLimit",
                      attrs: { "data-index": "creditLimit" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(record.creditLimit)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_credit_limit")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "total",
                      attrs: { "data-index": "total" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(record.total)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_total")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "remainingCreditLimit",
                      attrs: { "data-index": "remainingCreditLimit" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      record.remainingCreditLimit
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_remaining_credit_limit")))
                      ])
                    ]
                  ),
                  _vm.hasPrivilegeSOApproval
                    ? _c(
                        "a-table-column",
                        {
                          key: "action",
                          attrs: { width: 100, align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(text, record) {
                                  return [
                                    _c(
                                      "a-dropdown",
                                      {
                                        attrs: {
                                          trigger: ["click"],
                                          placement: "bottomCenter"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "more" },
                                          on: {
                                            click: function(e) {
                                              return e.preventDefault()
                                            }
                                          }
                                        }),
                                        _c(
                                          "a-menu",
                                          {
                                            attrs: { slot: "overlay" },
                                            slot: "overlay"
                                          },
                                          [
                                            _c(
                                              "a-menu-item",
                                              {
                                                attrs: {
                                                  disabled:
                                                    record.loadingApprove
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleApprove(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("lbl_approve")
                                                    )
                                                  )
                                                ]),
                                                _c("a-icon", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        record.loadingApprove,
                                                      expression:
                                                        "record.loadingApprove"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "loading",
                                                    spin: ""
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-menu-item",
                                              {
                                                attrs: {
                                                  disabled: record.loadingReject
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleReject(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("lbl_reject"))
                                                  )
                                                ]),
                                                _c("a-icon", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        record.loadingReject,
                                                      expression:
                                                        "record.loadingReject"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "loading",
                                                    spin: ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4265595117
                          )
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            justify: "center",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 8 } },
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtListSalesOrder.totalElements || 0) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 16, align: "end" } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtListSalesOrder.totalElements,
                  "page-size-set": _vm.queryParams.limit,
                  "id-pagination": "pagination1",
                  size: "small"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }