












































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListSalesOrder, ResponseSalesOrder } from "@interface/sales-order.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { salesOrderService } from "@service/sales-order.service";
import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { mapGetters } from "vuex";
import { IAuthorities } from "@interface/auth.interface";
import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { trimSpaceToUnderscore } from "@/helpers/common";

interface Row extends ResponseSalesOrder {
  key: any;
  loadingApprove: boolean;
  loadingReject: boolean;
}

export default Vue.extend({
  name: "CreditApproval",
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      dtSource: [] as Row[],
      loading: {
        table: false,
      },
      queryParams: {
        page: 0,
        limit: 20,
        sorts: "createdDate:desc",
        search: `state~${trimSpaceToUnderscore(SALES_ORDER_STATUS.WAIT_FOR_APPROVAL)}`
      } as RequestQueryParamsModel,
      dtListSalesOrder: {} as ResponseListSalesOrder,
    };
  },
  computed: {
    ...mapGetters([
      "authStore/GET_USER_PRIVILEGES"
    ]),
    hasPrivilegeSOApproval(): boolean {
      return !!this["authStore/GET_USER_PRIVILEGES"].find((x: IAuthorities) => x.key === "approval-sales-order" && x.privilege.update);
    },
  },
  created() {
    this.getList(this.queryParams);
  },
  methods: {
    getListSalesOrder(params: RequestQueryParamsModel): Promise<ResponseListSalesOrder> {
      return salesOrderService.getList(params);
    },
    responsePageSizeChange (response: ResponsePagination) {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange (response: ResponsePagination) {
      this.queryParams.page = response.page - 1;
      this.getList(this.queryParams);
    },
    async handleApprove(record: Row): Promise<void> {
      try {
        record.loadingApprove = true;
        await salesOrderService.approveSalesOrder(record.id);
        this.showUpdateSuccessMesssage();
      } catch (error) {
        this.showUpdateFailedMesssage();
      } finally {
        record.loadingApprove = false;
      }
    },
    async handleReject(record: Row): Promise<void> {
      try {
        record.loadingReject = true;
        await salesOrderService.rejectSalesOrder(record.id);
        this.showUpdateSuccessMesssage();
      } catch (error) {
        this.showUpdateFailedMesssage();
      } finally {
        record.loadingReject = false;
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.table = true;
        const res = await this.getListSalesOrder(params);
        this.dtListSalesOrder = res;
        this.dtSource = res.data.map(x => {
          return {
            key: x.id,
            loadingApprove: false,
            loadingReject: false,
            ...x
          };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
  }
});
